import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle, Dimensions } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { flatten } from "ramda"
import { IMAGESIZE, RotatingImage } from ".."
import { IconButton, Text, ArrowDownIcon, useBreakpointValue } from "native-base"
const { height } = Dimensions.get("window")

const CONTAINER: ViewStyle = {
  padding: spacing[6],
  height: height - 120,
  justifyContent: "space-between",
  alignItems: "center",
}

const CENTER: ViewStyle = {
  alignItems: "center",
}

const TEXT: TextStyle = {
  // fontFamily: typography.primary,
  fontSize: 14,
  // color: color.primary,
}

export interface LandingProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  downArrowPressed: () => void
}

/**
 * Describe your component here
 */
export const Landing = observer(function Landing(props: LandingProps) {
  const { style, downArrowPressed } = props
  const styles = flatten([CONTAINER, style])

  const imageSize = useBreakpointValue({
    base: IMAGESIZE.MEIDUM,
    sm: IMAGESIZE.LARGE,
    md: IMAGESIZE.LARGE,
    lg: IMAGESIZE.LARGE,
  })
  return (
    <View style={styles}>
      <View style={CENTER}>
        <RotatingImage size={imageSize} />
        <Text fontSize={["3xl", "5xl", "8xl"]} textAlign={"center"} color={"white"}>
          QueensBoro Buds
        </Text>
      </View>
      <View style={CENTER}>
        <Text fontSize={["2xl", "4xl", "7xl"]} textAlign={"center"} color={"white"}>
          +1 (718) 813-6582
        </Text>
        <Text
          fontSize={{ base: "2xl", sm: "3xl", md: "3xl", lg: "5xl" }}
          textAlign={"center"}
          color={"white"}
        >
          Schedule a local Delivery (Queens)
        </Text>
      </View>
      <View style={CENTER}>
        <IconButton
          onPress={downArrowPressed}
          icon={<ArrowDownIcon color={color.palette.white} size={44} />}
        />
      </View>
    </View>
  )
})
