import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { Screen } from "../../components"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color } from "../../theme"
import { FlatList, HStack, Center, Flex, Box, Text, Spinner } from "native-base"
import { OrderItem } from "../../components/order-item/order-item"
import API, { GraphQLResult, graphqlOperation } from "@aws-amplify/api"
import * as queries from "../../../src/graphql/queries"
import * as mutations from "../../../src/graphql/mutations"
import * as APIt from "../../../src/API"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
}
function OrdersHeader() {
  return (
    <Box flex={1}>
      <HStack>
        <Flex
          direction="row"
          w={"100%"}
          // mb="2.5"
          // mt="1.5"
          _text={{
            color: "coolGray.800",
          }}
        >
          <Center flex={4} size="16" bg="primary.100">
            Address
          </Center>
          <Center flex={2} size="16" bg="primary.200">
            Created
          </Center>
          <Center flex={2} bg="primary.300" size="16">
            Status
          </Center>
          <Center flex={1.5} size="16" bg="secondary.300">
            Orders
          </Center>
        </Flex>
      </HStack>
    </Box>
  )
}
export const OrdersScreen = observer(function OrdersScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const navigation = useNavigation()

  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  async function fetchOrders() {
    try {
      const allOrders: GraphQLResult<APIt.ListOrdersQuery> = await API.graphql(
        graphqlOperation(queries.ordersByUserIDAndCreatedAt, {
          userID: "walter",
          // date: {
          //   lt: dayjs().format(AWS_DATETIME_FORMAT),
          // },
          sortDirection: "DESC",
          limit: 100,
        }),
      )
      console.log("all", allOrders)
      setOrders(allOrders.data.ordersByUserIDAndCreatedAt.items)
    } catch (error) {
      console.log("Error is :", error)
      throw error
    }
  }

  async function updateStatus(orderID, status) {
    console.log("order id", orderID, status)
    setLoading(true)
    try {
      const orderDetails = { id: orderID, status }
      await API.graphql({
        query: mutations.updateOrder,
        variables: { input: orderDetails },
      })
      await fetchOrders()
      setLoading(false)
    } catch (error) {
      console.log("Error is :", error)
      throw error
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  return (
    <Screen style={ROOT} preset="scroll">
      <Text textAlign={"center"} color={"white"} p={"6"} fontSize={["xl", "2xl"]}>
        Orders
      </Text>
      {loading ? (
        <Spinner />
      ) : (
        <FlatList
          ListHeaderComponent={<OrdersHeader />}
          data={orders}
          renderItem={({ item }) => <OrderItem order={item} updateStatus={updateStatus} />}
        ></FlatList>
      )}
    </Screen>
  )
})
