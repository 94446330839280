/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      userID
      sms
      email
      name
      placeID
      placeDescription
      products
      window
      confirmed
      deliveryWindow
      status
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        sms
        email
        name
        placeID
        placeDescription
        products
        window
        confirmed
        deliveryWindow
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByUserIDAndCreatedAt = /* GraphQL */ `
  query OrdersByUserIDAndCreatedAt(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByUserIDAndCreatedAt(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sms
        email
        name
        placeID
        placeDescription
        products
        window
        confirmed
        deliveryWindow
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
