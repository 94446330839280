/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOrderInput = {
  id?: string | null,
  userID: string,
  sms?: string | null,
  email?: string | null,
  name?: string | null,
  placeID: string,
  placeDescription?: string | null,
  products?: Array< string | null > | null,
  window?: string | null,
  confirmed?: boolean | null,
  deliveryWindow?: string | null,
  status: Status,
  createdAt?: string | null,
};

export enum Status {
  ORDERED = "ORDERED",
  CONFIRMED = "CONFIRMED",
  DELIVERED = "DELIVERED",
}


export type ModelOrderConditionInput = {
  userID?: ModelIDInput | null,
  sms?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  placeID?: ModelStringInput | null,
  placeDescription?: ModelStringInput | null,
  products?: ModelStringInput | null,
  window?: ModelStringInput | null,
  confirmed?: ModelBooleanInput | null,
  deliveryWindow?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderConditionInput | null > | null,
  or?: Array< ModelOrderConditionInput | null > | null,
  not?: ModelOrderConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type Order = {
  __typename: "Order",
  id: string,
  userID: string,
  sms?: string | null,
  email?: string | null,
  name?: string | null,
  placeID: string,
  placeDescription?: string | null,
  products?: Array< string | null > | null,
  window?: string | null,
  confirmed?: boolean | null,
  deliveryWindow?: string | null,
  status: Status,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOrderInput = {
  id: string,
  userID?: string | null,
  sms?: string | null,
  email?: string | null,
  name?: string | null,
  placeID?: string | null,
  placeDescription?: string | null,
  products?: Array< string | null > | null,
  window?: string | null,
  confirmed?: boolean | null,
  deliveryWindow?: string | null,
  status?: Status | null,
  createdAt?: string | null,
};

export type DeleteOrderInput = {
  id: string,
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  sms?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  placeID?: ModelStringInput | null,
  placeDescription?: ModelStringInput | null,
  products?: ModelStringInput | null,
  window?: ModelStringInput | null,
  confirmed?: ModelBooleanInput | null,
  deliveryWindow?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOrderFilterInput | null > | null,
  or?: Array< ModelOrderFilterInput | null > | null,
  not?: ModelOrderFilterInput | null,
};

export type ModelOrderConnection = {
  __typename: "ModelOrderConnection",
  items:  Array<Order | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type CreateOrderMutationVariables = {
  input: CreateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type CreateOrderMutation = {
  createOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    sms?: string | null,
    email?: string | null,
    name?: string | null,
    placeID: string,
    placeDescription?: string | null,
    products?: Array< string | null > | null,
    window?: string | null,
    confirmed?: boolean | null,
    deliveryWindow?: string | null,
    status: Status,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrderMutationVariables = {
  input: UpdateOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type UpdateOrderMutation = {
  updateOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    sms?: string | null,
    email?: string | null,
    name?: string | null,
    placeID: string,
    placeDescription?: string | null,
    products?: Array< string | null > | null,
    window?: string | null,
    confirmed?: boolean | null,
    deliveryWindow?: string | null,
    status: Status,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrderMutationVariables = {
  input: DeleteOrderInput,
  condition?: ModelOrderConditionInput | null,
};

export type DeleteOrderMutation = {
  deleteOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    sms?: string | null,
    email?: string | null,
    name?: string | null,
    placeID: string,
    placeDescription?: string | null,
    products?: Array< string | null > | null,
    window?: string | null,
    confirmed?: boolean | null,
    deliveryWindow?: string | null,
    status: Status,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetOrderQueryVariables = {
  id: string,
};

export type GetOrderQuery = {
  getOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    sms?: string | null,
    email?: string | null,
    name?: string | null,
    placeID: string,
    placeDescription?: string | null,
    products?: Array< string | null > | null,
    window?: string | null,
    confirmed?: boolean | null,
    deliveryWindow?: string | null,
    status: Status,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrdersQueryVariables = {
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrdersQuery = {
  listOrders?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      userID: string,
      sms?: string | null,
      email?: string | null,
      name?: string | null,
      placeID: string,
      placeDescription?: string | null,
      products?: Array< string | null > | null,
      window?: string | null,
      confirmed?: boolean | null,
      deliveryWindow?: string | null,
      status: Status,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrdersByUserIDAndCreatedAtQueryVariables = {
  userID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByUserIDAndCreatedAtQuery = {
  ordersByUserIDAndCreatedAt?:  {
    __typename: "ModelOrderConnection",
    items:  Array< {
      __typename: "Order",
      id: string,
      userID: string,
      sms?: string | null,
      email?: string | null,
      name?: string | null,
      placeID: string,
      placeDescription?: string | null,
      products?: Array< string | null > | null,
      window?: string | null,
      confirmed?: boolean | null,
      deliveryWindow?: string | null,
      status: Status,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrderSubscription = {
  onCreateOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    sms?: string | null,
    email?: string | null,
    name?: string | null,
    placeID: string,
    placeDescription?: string | null,
    products?: Array< string | null > | null,
    window?: string | null,
    confirmed?: boolean | null,
    deliveryWindow?: string | null,
    status: Status,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrderSubscription = {
  onUpdateOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    sms?: string | null,
    email?: string | null,
    name?: string | null,
    placeID: string,
    placeDescription?: string | null,
    products?: Array< string | null > | null,
    window?: string | null,
    confirmed?: boolean | null,
    deliveryWindow?: string | null,
    status: Status,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrderSubscription = {
  onDeleteOrder?:  {
    __typename: "Order",
    id: string,
    userID: string,
    sms?: string | null,
    email?: string | null,
    name?: string | null,
    placeID: string,
    placeDescription?: string | null,
    products?: Array< string | null > | null,
    window?: string | null,
    confirmed?: boolean | null,
    deliveryWindow?: string | null,
    status: Status,
    createdAt: string,
    updatedAt: string,
  } | null,
};
