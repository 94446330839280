/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "googleMapsProxy",
            "endpoint": "https://tj70mbqtt5.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fwiczku5yrbdla54pdudi3bqqu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-elrkinduonaohhoqx5mocwasjm",
    "aws_cognito_identity_pool_id": "us-east-1:7f8dac3e-428c-4c1c-a348-15ebaa4f11cd",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_mobile_analytics_app_id": "5678ba11ba51415fa663a9be9cad2505",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
