export const palette = {
  black: "#1d1d1d",
  white: "#ffffff",
  offWhite: "#e6e6e6",
  orange: "#FBA928",
  orangeDarker: "#EB9918",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#dd3333",
  deepPurple: "#5D2555",
  blue: "#3850D2",
  lightness: "#B9C3FF",
  blue2: "#1835BA",
  blue3: "blue",
  green: "#326B21",
}
