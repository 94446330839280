import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { flatten } from "ramda"
import { ArrowDownIcon, Divider, IconButton, Text } from "native-base"

const CONTAINER: ViewStyle = {
  justifyContent: "space-evenly",
  alignItems: "center",
  padding: spacing[6],
  width: "80%",
  height: "100%",
  paddingVertical: spacing[6],
}

export interface AboutProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  downArrowPressed: () => void
}

/**
 * Describe your component here
 */
export const About = observer(function About(props: AboutProps) {
  const { style, downArrowPressed } = props
  const styles = flatten([CONTAINER, style])

  return (
    <View style={styles}>
      <Text underline fontWeight={"bold"} fontSize={["xl", "2xl", "3xl"]}>
        About
      </Text>

      <View>
        <Text fontSize={["3xl", "5xl"]} fontWeight={"bold"} textAlign={"center"}>
          Locally Grown, with love
        </Text>
      </View>
      <Divider thickness={"3"} style={{ backgroundColor: color.primary }} />

      <View>
        <Text fontSize={["md", "xl", "3xl"]} fontWeight={"medium"}>
          • Never touches plastic (Glass only)
        </Text>
        <Text fontSize={["md", "xl", "3xl"]} fontWeight={"medium"}>
          • Conflict Free
        </Text>
        <Text fontSize={["md", "xl", "3xl"]} fontWeight={"medium"}>
          • Amazing Quality
        </Text>
      </View>
      <Divider thickness={"3"} style={{ backgroundColor: color.primary }} />

      <Text fontSize={["xl", "2xl", "3xl"]} fontWeight={"semibold"} textAlign={"center"}>
        Grown with love in NYC Queens. QueensboroBuds is a small local boutique quality cultivator.
      </Text>
      <View style={{ alignItems: "center" }}>
        <IconButton
          onPress={downArrowPressed}
          icon={<ArrowDownIcon color={color.primary} size={44} />}
        />
      </View>
    </View>
  )
})
