/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React from "react"
import { ScrollView, ScrollViewBase, useColorScheme } from "react-native"
import { NavigationContainer, DefaultTheme, DarkTheme } from "@react-navigation/native"
import {
  WelcomeScreen,
  DemoScreen,
  DemoListScreen,
  HomeScreen,
  Section,
  OrdersScreen,
} from "../screens"
import { navigationRef } from "./navigation-utilities"
import { createDrawerNavigator, DrawerContentScrollView } from "@react-navigation/drawer"
import { Box, HStack, Icon, Pressable, VStack, Divider, Text } from "native-base"
import { color } from "../theme"
import { AppBar, LogoType } from "../components"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type NavigatorParamList = {
  ["QBB"]: undefined
  orders: undefined
  demo: undefined
  demoList: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/

const Drawer = createDrawerNavigator<NavigatorParamList>()

const getIcon = (screenName) => {
  switch (screenName) {
    case "Inbox":
      return "email"
    case "Outbox":
      return "send"
    case "Favorites":
      return "heart"
    case "Archive":
      return "archive"
    case "Trash":
      return "trash-can"
    case "Spam":
      return "alert-circle"
    default:
      return undefined
  }
}

function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props} safeArea>
      <VStack space="6" my="2" mx="1" divider={<Divider color={"white"} />}>
        <VStack space="3">
          <Box px="2">
            <LogoType />
            <Text bold fontSize={"2xl"}>
              Queensboro Buds
            </Text>
          </Box>
        </VStack>

        <VStack space="4">
          <VStack space="5">
            <VStack space="3">
              <Pressable
                px="5"
                py="3"
                onPress={() => {
                  props.navigation.closeDrawer()
                  props.descriptors[props.state.routes[0].key].options.scrollTo(Section.About)
                }}
              >
                <HStack space="7" alignItems="center">
                  {/* <Icon color="gray.500" size="5" as={<MaterialCommunityIcons name="bookmark" />} /> */}
                  <Text color="gray.900" fontWeight="500">
                    About
                  </Text>
                </HStack>
              </Pressable>
              <Pressable
                px="5"
                py="2"
                onPress={() => {
                  props.navigation.closeDrawer()
                  props.descriptors[props.state.routes[0].key].options.scrollTo(Section.Menu)
                }}
              >
                <HStack space="7" alignItems="center">
                  {/* <Icon color="gray.500" size="5" as={<MaterialCommunityIcons name="bookmark" />} /> */}
                  <Text color="gray.900" fontWeight="500">
                    Menu
                  </Text>
                </HStack>
              </Pressable>
              <Pressable
                alignSelf={"center"}
                px="5"
                py="3"
                borderRadius={"9"}
                background={color.primary}
                onPress={() => {
                  props.navigation.closeDrawer()
                  props.descriptors[props.state.routes[0].key].options.showForm()
                }}
              >
                <HStack space="7" alignItems="center">
                  <Text fontWeight="500" color="white">
                    Buy Bud
                  </Text>
                </HStack>
              </Pressable>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </DrawerContentScrollView>
  )
}

function MyDrawer() {
  return (
    // <Box safeArea flex={1}>
    <Drawer.Navigator
      screenOptions={{
        // eslint-disable-next-line react/display-name
        header: (props) => {
          return (
            <AppBar
              aboutPressed={() => {
                props.options.scrollTo(Section.About)
              }}
              menuPressed={() => {
                props.options.scrollTo(Section.Menu)
              }}
              buyBudPressed={() => {
                props.options.showForm()
              }}
              showDrawer={() => {
                props.navigation.openDrawer()
              }}
            />
          )
        },
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen name="QBB" component={HomeScreen} options={{ title: null }} />
      <Drawer.Screen name="orders" component={OrdersScreen} options={{}} />
    </Drawer.Navigator>
    // </Box>
  )
}

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = (props: NavigationProps) => {
  const colorScheme = useColorScheme()

  const linking = {
    prefixes: ["localhost:19006", "https://queensborobuds.com", "mychat://"],
    config: {
      screens: {
        QBB: "",
        orders: "orders",
      },
    },
  }

  return (
    <NavigationContainer
      linking={linking}
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      <MyDrawer /> :
    </NavigationContainer>
  )
}

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["home"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
