import * as React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color } from "../../theme"
import { flatten } from "ramda"
import { SuccessAnimation } from "react-native-success-animation"
import { Text } from "native-base"

const SIZE = 75
const CONTAINER: ViewStyle = {
  justifyContent: "center",
}

export interface SuccessProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  prompt?: string
}

/**
 * Describe your component here
 */
export const Success = observer(function Success(props: SuccessProps) {
  const { style, prompt = "Successfully Subscribed!" } = props
  const styles = flatten([CONTAINER, style])

  return (
    <View style={styles}>
      <SuccessAnimation
        size={SIZE}
        iconSize={SIZE * 0.7}
        dotColor={color.primary}
        iconColor={"white"}
        dotSize={20}
        duration={2000}
        backgroundColor={color.primary}
        animatedLayerColor={"white"}
        // onAnimationEnd={() => alert("Animation Ended")}
      />
      <Text color={"white"} py={"2"} textAlign="center" mt="3" fontSize={["xl", "2xl", "4xl"]}>
        {prompt}
      </Text>
    </View>
  )
})
