import React, { useEffect } from "react"
import { ImageSourcePropType, ImageStyle, StyleProp, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { flatten } from "ramda"
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
  // Transition,
  // Transitioning,
} from "react-native-reanimated"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
}

const smallMult = 0.18
const IMAGESMALL: ImageStyle = {
  margin: spacing[4],
  height: 420 * smallMult,
  width: 411 * smallMult,
}
const medMult = 0.45
const IMAGEMEDIUM: ImageStyle = {
  margin: spacing[2],
  marginHorizontal: spacing[8],
  height: 420 * medMult,
  width: 411 * medMult,
}
const IMAGELARGE: ImageStyle = {
  margin: spacing[5],
  // marginHorizontal: spacing[5],
  height: 420,
  width: 411,
}

export enum IMAGESIZE {
  SMALL,
  MEIDUM,
  LARGE,
}
export interface RotatingImageProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  rotateLogo?: boolean
  source?: ImageSourcePropType
  size?: IMAGESIZE
}

/**
 * Describe your component here
 */
export const RotatingImage = observer(function RotatingImage(props: RotatingImageProps) {
  const {
    style,
    size = IMAGESIZE.SMALL,
    rotateLogo,
    source = require("../../../assets/images/head1.svg"),
  } = props
  const styles = flatten([CONTAINER, style])

  var rotation = useSharedValue(0)

  var animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotateY: `${rotation.value}deg` }],
    }
  })

  useEffect(() => {
    if (rotateLogo)
      rotation.value = withRepeat(
        withTiming(180, { duration: 1250, easing: Easing.ease }),
        -1,
        true,
      )

    // animate()

    return () => {
      // cleanup
    }
  }, [])
  var imageStyle = null

  switch (size) {
    case IMAGESIZE.SMALL:
      imageStyle = IMAGESMALL
      break
    case IMAGESIZE.MEIDUM:
      imageStyle = IMAGEMEDIUM

      break
    case IMAGESIZE.LARGE:
      imageStyle = IMAGELARGE
      break

    default:
      imageStyle = IMAGESMALL
      break
  }

  return (
    <View style={styles}>
      <Animated.Image style={[imageStyle, animatedStyle]} source={source} />
    </View>
  )
})
