import * as React from "react"
import { StyleProp, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Box, Center, Flex, HStack, Select, VStack, Text } from "native-base"
import * as timeago from "timeago.js"
import { Order, Status } from "../../../src/API"
import { MaterialIcons } from "@expo/vector-icons"

export interface OrderItemProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  order: Order
  updateStatus: (orderID: string, status: string) => void
}

function IconForStatus(status: Status) {
  switch (status) {
    case Status.ORDERED:
      return <MaterialIcons name="add-task" size={18 * 1.5} color={"orange"} />

    case Status.CONFIRMED:
      return <MaterialIcons name="delivery-dining" size={18 * 1.5} color={"blue"} />

    case Status.DELIVERED:
      return <MaterialIcons name="check" size={18 * 1.5} color={"green"} />

    default:
      break
  }
}

const STATUSES = ["ORDERED", "CONFIRMED", "DELIVERED"]
/**
 * Describe your component here
 */
export const OrderItem = observer(function OrderItem(props: OrderItemProps) {
  const { order, updateStatus } = props

  return (
    <Box
      flex={1}
      borderBottomWidth="1"
      bg="white"
      _dark={{
        borderColor: "gray.600",
      }}
      borderColor="blue.200"
    >
      <HStack>
        <Flex
          direction="row"
          w={"100%"}
          h={"100%"}
          _text={{
            color: "coolGray.800",
          }}
        >
          <Center
            borderColor="blue.200"
            borderRightWidth={"2"}
            borderRightColor={"blue.700"}
            flex={4}
          >
            {order.placeDescription}
          </Center>
          <Center
            borderColor="blue.200"
            borderRightWidth={"2"}
            borderRightColor={"blue.700"}
            flex={2}
          >
            {timeago.format(order.createdAt, "en_US")}
          </Center>
          <Center
            borderColor="blue.200"
            borderRightWidth={"2"}
            borderRightColor={"blue.700"}
            flex={2}
          >
            <VStack m="1.5" alignItems={"center"} space={"1.5"}>
              <Text>{order.status}</Text>
              {IconForStatus(order.status)}

              <Select
                // isDisabled={true}
                // _text={{ color: "red.100", textColor: "red.100" }}
                defaultValue="Pick a time.."
                placeholderTextColor={"gray.900"}
                color={"white"}
                placeholder="Update Status"
                // selectedValue={order.status}
                width={150}
                onValueChange={(newValue) => {
                  updateStatus(order.id, newValue)
                }}
              >
                {STATUSES.map((status) => {
                  return (
                    <Select.Item
                      key={status}
                      color={"blue"}
                      label={status}
                      value={status}
                    ></Select.Item>
                  )
                })}
              </Select>
              {/* <Button onPress={updateStatus}>Update</Button> */}
            </VStack>
          </Center>
          <Center
            borderColor="blue.200"
            borderRightWidth={"2"}
            borderRightColor={"blue.700"}
            flex={1.5}
          >
            {order.products}
          </Center>
        </Flex>
      </HStack>
    </Box>
  )
})
