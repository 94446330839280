import React from "react"
import { StyleProp, View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { color } from "../../theme"

import { flatten } from "ramda"
import { LogoType } from ".."
import { Button, Box, HamburgerIcon, HStack, useBreakpointValue } from "native-base"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  width: "100%",
  shadowColor: "black",
  shadowRadius: 2,
  shadowOffset: { width: 1, height: 1 },
}

export interface AppBarProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  aboutPressed: () => void
  menuPressed: () => void
  contactPressed?: () => void
  buyBudPressed: () => void
  showDrawer: () => void
}

/**
 * Describe your component here
 */
export const AppBar = observer(function AppBar(props: AppBarProps) {
  const { style, aboutPressed, menuPressed, contactPressed, buyBudPressed, showDrawer } = props
  const styles = flatten([CONTAINER, style])

  /**
   *
   *    breakpoints: {
        base: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
        '2xl': number;
    };

   *
   */
  const showHamburger = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
  })

  return (
    <View style={styles}>
      <View style={{ width: "100%" }}>
        {/* <StatusBar backgroundColor="#3700B3" barStyle="light-content" /> */}

        <Box
          safeAreaTop //backgroundColor={color.primary}
        />

        <HStack
          bg={color.palette.offWhite}
          px="1"
          py="3"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack space="4" alignItems="center">
            <LogoType />
          </HStack>

          {showHamburger ? (
            <HStack space="3" mr={"3"}>
              <Button style={{ backgroundColor: color.palette.blue3 }} onPress={buyBudPressed}>
                Buy Bud
              </Button>
              <TouchableOpacity onPress={showDrawer}>
                <HamburgerIcon color={color.primary} />
              </TouchableOpacity>
            </HStack>
          ) : (
            <HStack space="3" mr={"3"}>
              <Button background={color.palette.black} onPress={aboutPressed}>
                About
              </Button>
              <Button background={color.palette.black} onPress={menuPressed}>
                Menu
              </Button>
              {/* <Button onPress={contactPressed}>Contact</Button> */}
              <Button style={{ backgroundColor: color.primary }} onPress={buyBudPressed}>
                Buy Bud
              </Button>
            </HStack>
          )}
        </HStack>
      </View>
    </View>
  )
})
