import * as React from "react"
import { Image, ImageStyle, StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
// import { Text } from "../text/text"
import { flatten } from "ramda"
import { VStack, Box, Divider, Text, Stack } from "native-base"

import { Button } from ".."

const CONTAINER: ViewStyle = {
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  backgroundColor: color.primary,
  padding: spacing[5],
}

const PRICE: ViewStyle = {
  backgroundColor: color.primary,
  borderRadius: 9,
  width: "auto",
  alignItems: "center",
  padding: spacing[2],
}
const IMAGE: StyleProp<ImageStyle> = {
  alignSelf: "center",
  width: 200,
  height: 200, // margin: spacing[3]
}

const PRICETEXT: TextStyle = { fontWeight: "400", color: color.palette.white, alignSelf: "center" }

export interface MenuProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  orderPressed: () => void
}

const MENU = [
  {
    title: "Double Grape",
    bullets: [
      {
        title: "Aroma and Flavour",
        description:
          "Strong complex aroma, blending sweet and sour, grapes and gas. Tastes of a deep, rich red grape with a sweet grape pop",
      },
      {
        title: "Effect",
        description:
          "Potent hybrid, both stimulating and sedative depending on tolerance and consumption",
      },
      {
        title: "Extract",
        description: "PotPerfect extract candidate - Very High in resin, oil and terps.",
      },
      {
        title: "Cannabinoids",
        description:
          "21.78% THC from a random flower tested by Canna, Independent tests have seen as high as 31% total cannabinoids.",
      },
      {
        title: "Medicinal Effect",
        description: "Great for aches, pains, sleep and appetite stimulation",
      },
    ],
    priceOZ: 250,
    priceQuarterOZ: 75,
    image: require("../../../assets/images/double-grape.png"),
  },
  {
    title: "Sour Stomper",
    priceOZ: 250,
    priceQuarterOZ: 75,
    image: require("../../../assets/images/Sour-Stomper.png"),
    bullets: [
      {
        title: "Aroma and Flavour",
        delicious:
          "Delicious sweet red grape infused with a raw funky to meaty edge. Tastes grapey with piney-lemony undertones",
      },
      {
        title: "Effect",
        description:
          "Glorious, hitting all the pleasure zones, euphoric and feel good vibes. Brings a sunny shine to a cloudy day",
      },
      {
        title: "Extract",
        description: "Frost drips off this girl, perfect candidate for extractions",
      },
      { title: "Cannabinoids", description: "TBA" },
      { title: "Medicinal Effect", description: "Anti-depressant qualities" },
    ],
  },
  {
    title: "Skywalker OG",
    priceOZ: 220,
    priceQuarterOZ: 70,
    image: require("../../../assets/images/Samsquanch-OG.png"),
    bullets: [
      {
        title: "Aroma and Flavour",
        description:
          "Pungent with a Capital P OG, earthy, piney, dash of lemon. Flavour is a mouth coating blend of barthy, pungent and sweet rolled into one.",
      },

      {
        title: "Effect",
        description:
          "Strong, couchlocking, straight to the legs and straight to the head, and will take you to your own world of planet Skywalker.",
      },
      { title: "Extract", description: "TBA" },
      { title: "Cannabinoids", description: "TBA" },

      {
        title: "Medicinal Effect",
        description: "Great for anxiety and pain relief, promotes well being.",
      },
    ],
  },
]
/**
 * Describe your component here
 */
export const Menu = observer(function Menu(props: MenuProps) {
  const { style, orderPressed } = props
  const styles = flatten([CONTAINER, style])

  return (
    <View style={styles}>
      <Text underline color={"white"} fontWeight={"bold"} fontSize={["xl", "2xl", "3xl"]}>
        Buds
      </Text>

      <Stack
        direction={["column", "column", "row"]}
        style={{
          alignItems: "center",
        }}
        justifyContent={["center", "space-between"]}
        alignSelf={"center"}
        // rounded="lg"
        // overflow="hidden"
        // width={["100", "100", "4/6"]}
        p="10"
        w={"100%"}
        space={"3"}
        // _light={{ backgroundColor: "coolGray.50" }}
        // _dark={{ backgroundColor: "gray.700" }}
      >
        {MENU.map((item, index) => {
          return <Item key={String(index)} itemKey={index} item={item} />
        })}
      </Stack>

      <Button preset="secondary" text="Order Bud" onPress={orderPressed} />
    </View>
  )
})

function BulletItem({ title = "TITLE", description = "DESCRIPTION", bulletKey }) {
  return (
    <Box key={bulletKey} p={"0"} px={"3"}>
      <Text fontSize={["lg"]} fontWeight="semibold">
        {title}
      </Text>
      <Text fontSize={"sm"}>{description}</Text>
    </Box>
  )
}

function Item({ item, itemKey }) {
  return (
    <Box
      // border="1"
      key={itemKey}
      borderRadius="md"
      shadow="1"
      h={1050}
      w={"250"}
      background={color.palette.offWhite}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box>
        <Box px="4" pt="4" pb={"2"}>
          <Text fontWeight={"bold"} alignContent={"center"} fontSize={["2xl", "3xl"]}>
            {item.title}
          </Text>
        </Box>
        <Box pb={"4"}>
          <Image style={IMAGE} source={item.image} resizeMode="contain" />
        </Box>

        <VStack space="4" divider={<Divider />}>
          {item.bullets.map((bullet) => {
            return (
              <BulletItem
                key={bullet.title + bullet.description}
                bulletKey={bullet.title + bullet.description}
                title={bullet.title}
                description={bullet.description}
              />
            )
          })}
        </VStack>
      </Box>
      <Box px="4" pb="4">
        <VStack space={"3"}>
          <View style={PRICE}>
            <Text alignItems={"center"} color={"white"} fontSize={"2xl"} fontWeight={"bold"}>
              OZ - ${item.priceOZ}
            </Text>
          </View>
          <View style={PRICE}>
            <Text color={"white"} fontSize={"2xl"} fontWeight={"bold"}>
              Quarter OZ - ${item.priceQuarterOZ}
            </Text>
          </View>
        </VStack>
      </Box>
    </Box>
  )
}
