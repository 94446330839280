import React, { useState, useEffect } from "react"
import { StyleProp, View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"
import { Box, HStack, Image, VStack, Text } from "native-base"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  flex: 1,
  alignItems: "center",
}

export interface StrainsProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>

  onChange: (number: []) => void
}
function Strain({ image, key, title, onPress, selected }) {
  const borderColor = !selected ? "blueGray.300" : "blue.600"
  return (
    <TouchableOpacity key={key} onPress={onPress}>
      <Box
        rounded="sm"
        background={selected ? "blueGray.700" : "gray.500"}
        alignItems={"center"}
        pb={"4"}
        borderWidth={"3"}
        borderColor={borderColor}
      >
        <Image
          mt={["1", "3"]}
          alt={title}
          w={["20", "50", "100"]}
          h={["20", "50", "100"]}
          source={image}
          resizeMode="contain"
        />
        <Text p={["2"]} color="white" fontSize={["lg", "sm", "lg", "2lg"]}>
          {title}
        </Text>
      </Box>
    </TouchableOpacity>
  )
}
/**
 * Describe your component here
 */
export const Strains = observer(function Strains(props: StrainsProps) {
  const { style, onChange } = props
  const styles = flatten([CONTAINER, style])
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    onChange(selectedItems)
    return () => {
      //
    }
  }, [selectedItems])
  return (
    <View style={styles}>
      <VStack alignItems={"center"}>
        <Box alignSelf={"center"} w="100%">
          <HStack
            direction={["column", "row", "row", "row", "row"]}
            space={["5", "2xl"]}
            // background={"red.100"}
            // space={"2xl"}
            alignItems={"center"}
            justifyContent={"space-evenly"}
          >
            {MENU.map((item, index) => {
              const selectedd = selectedItems.includes(index)
              return (
                <Strain
                  onPress={() => {
                    const indexx = selectedItems.indexOf(index)
                    if (indexx > -1) {
                      const copySI = [...selectedItems]
                      copySI.splice(indexx, 1)
                      setSelectedItems(copySI)
                    } else {
                      setSelectedItems([...selectedItems, index])
                    }
                  }}
                  image={item.image}
                  title={item.title}
                  selected={selectedd}
                />
              )
            })}
          </HStack>
        </Box>
      </VStack>
    </View>
  )
})

const MENU = [
  {
    title: "Double Grape",
    bullets: [
      {
        title: "Aroma and Flavour",
        description:
          "Strong complex aroma, blending sweet and sour, grapes and gas. Tastes of a deep, rich red grape with a sweet grape pop",
      },
      {
        title: "Effect",
        description:
          "Potent hybrid, both stimulating and sedative depending on tolerance and consumption",
      },
      {
        title: "Extract",
        description: "PotPerfect extract candidate - Very High in resin, oil and terps.",
      },
      {
        title: "Cannabinoids",
        description:
          "21.78% THC from a random flower tested by Canna, Independent tests have seen as high as 31% total cannabinoids.",
      },
      {
        title: "Medicinal Effect",
        description: "Great for aches, pains, sleep and appetite stimulation",
      },
    ],
    priceOZ: 250,
    priceQuarterOZ: 75,
    image: require("../../../assets/images/double-grape.png"),
    selected: false,
  },
  {
    title: "Sour Stomper",
    priceOZ: 250,
    priceQuarterOZ: 75,
    image: require("../../../assets/images/Sour-Stomper.png"),
    selected: false,
    bullets: [
      {
        title: "Aroma and Flavour",
        delicious:
          "Delicious sweet red grape infused with a raw funky to meaty edge. Tastes grapey with piney-lemony undertones",
      },
      {
        title: "Effect",
        description:
          "Glorious, hitting all the pleasure zones, euphoric and feel good vibes. Brings a sunny shine to a cloudy day",
      },
      {
        title: "Extract",
        description: "Frost drips off this girl, perfect candidate for extractions",
      },
      { title: "Cannabinoids", description: "TBA" },
      { title: "Medicinal Effect", description: "Anti-depressant qualities" },
    ],
  },
  {
    title: "Skywalker OG",
    priceOZ: 220,
    priceQuarterOZ: 70,
    image: require("../../../assets/images/Samsquanch-OG.png"),
    selected: false,
    bullets: [
      {
        title: "Aroma and Flavour",
        description:
          "Pungent with a Capital P OG, earthy, piney, dash of lemon. Flavour is a mouth coating blend of barthy, pungent and sweet rolled into one.",
      },

      {
        title: "Effect",
        description:
          "Strong, couchlocking, straight to the legs and straight to the head, and will take you to your own world of planet Skywalker.",
      },
      { title: "Extract", description: "TBA" },
      { title: "Cannabinoids", description: "TBA" },

      {
        title: "Medicinal Effect",
        description: "Great for anxiety and pain relief, promotes well being.",
      },
    ],
  },
]
