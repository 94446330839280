import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../text/text"
import { flatten } from "ramda"
import { SubscribeEmail, Success } from ".."

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  height: "100%",
}

export interface SubscribeProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  subscribed: boolean
  submit: (email, type, callback) => void
}

/**
 * Describe your component here
 */
export const Subscribe = observer(function Subscribe(props: SubscribeProps) {
  const { style, subscribed, submit } = props
  const styles = flatten([CONTAINER, style])

  return (
    <View style={styles}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "flex-end",
          padding: spacing[3],
        }}
      >
        {subscribed ? (
          <Success />
        ) : (
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "flex-end",
              marginRight: spacing[3],
              flexDirection: "column",
            }}
          >
            <SubscribeEmail onSubmit={submit} />
            <Text
              style={{ marginTop: spacing[2] }}
              preset="fieldLabel"
              text="Sign up to receive exclusive updates on new drops!"
            />
          </View>
        )}
      </View>
    </View>
  )
})
