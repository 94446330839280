import React, { useState, useEffect } from "react"
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, typography } from "../../theme"
import { flatten } from "ramda"
import { Box, HStack, Select, VStack, Text, AddIcon } from "native-base"

function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max))
}

const CONTAINER: ViewStyle = {
  // justifyContent: "center",
  // backgroundColor: "red",
  // alignItems: "flex-end",
}

export interface Item {
  name: string
  quantity: number
  sizes: [string]
}
export interface Product {
  name: string
  quantity: number
  size: string
  price: number
  inventory: number
}
export interface OrderItemsProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>

  items: [Item]
  prodcuts: [Product]
}
const PRODUCTS = [
  { id: "001", name: "Sour Stomper", size: "1/4 Oz", price: 7000, inventory: 99 },
  { id: "002", name: "Sour Stomper", size: "1/2 Oz", price: 15000, inventory: 25 },
  { id: "003", name: "Sour Stomper", size: "1 Oz", price: 25000, inventory: 9 },

  { id: "004", name: "Skylwalker OG", size: "1/4 Oz", price: 7000, inventory: 19 },
  { id: "005", name: "Skylwalker OG", size: "1/2 Oz", price: 15000, inventory: 29 },
  { id: "006", name: "Skylwalker OG", size: "1 Oz", price: 25000, inventory: 5 },

  { id: "007", name: "Double Grape", size: "1/4 Oz", price: 7000, inventory: 19 },
  { id: "008", name: "Double Grape", size: "1/2 Oz", price: 15000, inventory: 29 },
  { id: "009", name: "Double Grape", size: "1 Oz", price: 25000, inventory: 3 },
]
const ITEMS = [
  { id: "001", name: "Sour Stomper", sizes: ["1/4 Oz", "1/2 Oz", "1 1Oz"] },
  { id: "002", name: "Skylwalker OG", sizes: ["1/4 Oz", "1/2 Oz", "1 Oz"] },

  { id: "003", name: "Double Grape", sizes: ["1/4 Oz", "1/2 Oz", "1 Oz"] },
]
/**
 * Describe your component here
 */
export const OrderItems = observer(function OrderItems(props: OrderItemsProps) {
  const { style, items = ITEMS, products = PRODUCTS } = props
  const styles = flatten([CONTAINER, style])

  const [rows, setRows] = useState([{}])

  const [currentRowIndex, setCurrentRowIndex] = useState(0)
  const [ttem, setItem] = useState(null)
  const [size, setSize] = useState(null)
  const [quantity, setQuantity] = useState(null)
  const [total, setTotal] = useState(0)
  const enabled = ttem && size && quantity

  useEffect(() => {
    updateTotals()

    return () => {
      // effect
    }
  }, [rows])

  async function updateTotals() {
    var newTotal
    for await (const row of rows) {
      console.log("row is ", row)
    }
    setTotal(newTotal)
  }

  // filter by item
  function currentSizes() {
    const filtered = products.filter((product) => product.name === ttem)

    const sizes = filtered.map((product) => product.size)
    return sizes.map((size) => {
      return <Select.Item label={size} value={size} key={size} />
    })
  }

  function currentQuantities() {
    const filtered = products.filter((product) => product.name === ttem && product.size === size)

    console.log("filtered ", filtered)
    const quantity = filtered.map((product) => product.inventory)
    console.log("quantity", quantity[0])

    const clampedValue = clamp(quantity, 0, 10)
    console.log(clampedValue, "campledValue")
    // quantity always shows max of ten or remaning.
    var items = []
    for (var i = 0; i < clampedValue; i++) {
      items.push(<Select.Item label={i} value={i} key={i} />)
    }
    return items
  }

  // set for unique values
  const visisbleProducts = new Set(products.map((product) => product.name))
  const quantityVisible = ttem !== null && size !== null
  console.log(quantityVisible, "quantityVisible")
  console.log(ttem, "quantityVisible")
  console.log(size, "quantityVisible")
  return (
    <Box style={styles}>
      <VStack>
        <Text color={"white"} fontSize={["sm", "md", "lg"]}>
          Create your order
        </Text>
        <Box pl={"5"}>
          {rows.map((item, index) => {
            return (
              <Box key={index}>
                <HStack alignItems={"flex-start"} justifyContent={"center"}>
                  <Box px={["1", "2"]}>
                    <Select
                      // w={["2/3"]}
                      placeholderTextColor={"gray.400"}
                      color={"white"}
                      fontSize={[8, "sm", "md", "lg"]}
                      width={[75, 75, 100, 150]}
                      placeholder="Select strain"
                      // selectedValue={item.name}
                      onValueChange={(itemValue: string) => {
                        //TODO: set current  item

                        setItem(itemValue)
                      }}
                    >
                      {[...visisbleProducts].map((subItem) => {
                        console.log("subsitem , ", subItem)
                        return <Select.Item label={subItem} value={subItem} key={subItem} />
                      })}
                    </Select>
                  </Box>
                  <Box px={["1", "2"]}>
                    <Select
                      isDisabled={!ttem}
                      placeholderTextColor={"gray.400"}
                      color={"white"}
                      placeholder="Select Size"
                      // selectedValue={size}
                      fontSize={[8, "sm", "md", "lg"]}
                      width={[75, 75, 100, 150]}
                      onValueChange={(itemValue: string) => setSize(itemValue)}
                    >
                      {currentSizes()}
                    </Select>
                  </Box>
                  <Box px={["1", "2"]}>
                    <Select
                      isDisabled={!quantityVisible}
                      placeholderTextColor={"gray.400"}
                      color={"white"}
                      placeholder="Select Quantity"
                      // selectedValue={quantity}
                      width={[75, 75, 100, 150]}
                      onValueChange={(itemValue: string) => setQuantity(itemValue)}
                    >
                      {currentQuantities()}
                    </Select>
                  </Box>
                  {rows.length - 1 === index && (
                    <Box>
                      <TouchableOpacity
                        disabled={!enabled}
                        onPress={() => {
                          // Remove last item which is placeholder
                          const filtered = [rows].filter((value, index, array) => {
                            return rows.length - 1 !== index
                          })
                          const newRows = [
                            ...filtered,
                            {
                              name: ttem,
                              selectedQuantity: quantity,
                              selectedSize: size,
                              sizes: [],
                            },
                            { sizes: [] },
                          ]
                          console.log("filtered", filtered)
                          console.log("new rows", newRows)
                          setRows(newRows)
                          setItem(null)
                          setSize(null)
                          setQuantity(null)
                          setCurrentRowIndex(currentRowIndex + 1)
                        }}
                      >
                        <AddIcon color={!enabled ? "gray.500" : "white"} size={28} />
                      </TouchableOpacity>
                    </Box>
                  )}
                </HStack>
              </Box>
            )
          })}
        </Box>
        <Text color="white">{total}</Text>
      </VStack>
    </Box>
  )
})
