/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigators, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignore-warnings"
import React, { useState, useEffect } from "react"
import { SafeAreaProvider, initialWindowMetrics } from "react-native-safe-area-context"
import { initFonts } from "./theme/fonts" // expo
import * as storage from "./utils/storage"
import { useBackButtonHandler, AppNavigator, canExit, useNavigationPersistence } from "./navigators"
import { RootStore, RootStoreProvider, setupRootStore } from "./models"
import { ToggleStorybook } from "../storybook/toggle-storybook"
import { ErrorBoundary } from "./screens/error/error-boundary"
import { extendTheme, NativeBaseProvider } from "native-base"
import Amplify from "aws-amplify"
import config from "../src/aws-exports"
import { ToastProvider } from "react-native-toast-notifications"

if (typeof window !== "undefined") {
  require("smoothscroll-polyfill").polyfill()
}

Amplify.configure(config)
// This puts screens in a native ViewController or Activity. If you want fully native
// stack navigation, use `createNativeStackNavigator` in place of `createStackNavigator`:
// https://github.com/kmagiera/react-native-screens#using-native-stack-navigator

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"
const newColorTheme = {
  primary: {
    50: "#e5e7ff",
    100: "#b9bdfb",
    200: "#8d96f2",
    300: "#6071ec",
    400: "#344de5",
    500: "#1a3acb",
    600: "#12239f",
    700: "#0b1273",
    800: "#040548",
    900: "#03001e",
  },
  secondary: {
    50: "#ebfbe6",
    100: "#cdeec3",
    200: "#ade19e",
    300: "#8dd578",
    400: "#6dc952",
    500: "#54af39",
    600: "#41882b",
    700: "#2e611e",
    800: "#193a10",
    900: "#041400",
  },
  brand: {
    900: "#8287af",
    800: "#7c83db",
    700: "#b3bef6",
  },
}
const theme = extendTheme({ colors: newColorTheme })

/**
 * This is the root component of our app.
 */
function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  useBackButtonHandler(canExit)
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    ;(async () => {
      await initFonts() // expo
      setupRootStore().then(setRootStore)
    })()
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color.
  // In iOS: application:didFinishLaunchingWithOptions:
  // In Android: https://stackoverflow.com/a/45838109/204044
  // You can replace with your own loading component if you wish.
  if (!rootStore || !isNavigationStateRestored) return null

  // otherwise, we're ready to render the app
  return (
    <ToggleStorybook>
      <ToastProvider>
        <NativeBaseProvider theme={theme}>
          <RootStoreProvider value={rootStore}>
            <SafeAreaProvider initialMetrics={initialWindowMetrics}>
              <ErrorBoundary catchErrors={"always"}>
                <AppNavigator
                  initialState={initialNavigationState}
                  onStateChange={onNavigationStateChange}
                />
              </ErrorBoundary>
            </SafeAreaProvider>
          </RootStoreProvider>
        </NativeBaseProvider>
      </ToastProvider>
    </ToggleStorybook>
  )
}

export default App
