import React from "react"
import { StyleProp, TextStyle, ViewStyle, ImageSourcePropType } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../text/text"
import { flatten } from "ramda"
import { RotatingImage } from ".."
import { Box, Hidden } from "native-base"

const CONTAINER: ViewStyle = {
  justifyContent: "flex-start",
  backgroundColor: color.primary,
  borderRadius: 9,
  // padding: spacing[3],
  // paddingHorizontal: spacing[6],
  flexDirection: "row",
  alignItems: "center",
  marginRight: spacing[3],
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontWeight: "900",
  fontStyle: "normal",
  lineHeight: 200.39 * 0.4,
  letterSpacing: -1.5,
  color: color.palette.white,
}

export interface LogoTypeProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  rotateLogo?: boolean
  source?: ImageSourcePropType
  title?: string
}

/**
 * Describe your component here
 */
export const LogoType = observer(function LogoType(props: LogoTypeProps) {
  const { style, rotateLogo = false, source, title = "QueensBoro Buds" } = props
  const styles = flatten([CONTAINER, style])

  return (
    <Box flexDirection={{ base: "column", md: "column" }} w={[100, 250, 325]} style={styles}>
      <RotatingImage source={source} rotateLogo={rotateLogo} />
      <Hidden from="base" till="sm">
        <Text style={{ textAlign: "left", marginLeft: 0, marginRight: spacing[2] }} preset="header">
          {title}
        </Text>
      </Hidden>
    </Box>
  )
})
