import React, { useCallback } from "react"
import { StyleProp, View, ViewStyle, Linking } from "react-native"
import { observer } from "mobx-react-lite"
import { color } from "../../theme"
import { flatten } from "ramda"
import { RotatingImage, Subscribe } from ".."
import { AntDesign } from "@expo/vector-icons"
import { Icon } from "native-base"
import { TouchableOpacity } from "react-native-gesture-handler"

const CONTAINER: ViewStyle = {
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "row",
  width: "100%",
  backgroundColor: color.palette.black,
  height: 120,
}

export interface FooterProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  subscribed: boolean
  submit: (email, type, callback) => void
}
const url = "https://www.instagram.com/queensborobuds"

/**
 * Describe your component here
 */
export const Footer = observer(function Footer(props: FooterProps) {
  const { style, subscribed, submit } = props
  const styles = flatten([CONTAINER, style])
  const handlePress = useCallback(async () => {
    const supported = await Linking.canOpenURL(url)

    if (supported) {
      await Linking.openURL(url)
    } else {
      // Alert.alert(`Don't know how to open this URL: ${url}`)
    }
  }, [url])

  return (
    <View style={styles}>
      <RotatingImage />
      <TouchableOpacity onPress={handlePress}>
        <Icon
          as={AntDesign}
          name="instagram"
          color={color.palette.white}
          // _dark={{
          //   color: "warmGray.50",
          // }}
        />
      </TouchableOpacity>
      {/* <View
        style={{
          flex: 4, //alignItems: "center"
          justifyContent: "flex-end",
        }}
      >
        <Subscribe submit={submit} subscribed={subscribed} />
      </View> */}
    </View>
  )
})
