import React, { useState, useEffect } from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, typography } from "../../theme"
import { Text } from "../text/text"
import { flatten } from "ramda"
import { Input, Button, FormControl, WarningOutlineIcon } from "native-base"
import validator from "validator"

const CONTAINER: ViewStyle = {
  flex: 1,
  alignItems: "center",
  paddingBottom: 0,
}

export enum InputType {
  Email,
  SMS,
  None,
}
export interface SubscribeEmailProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>

  onSubmit: (
    email: string,
    type: InputType,
    callback: (success: boolean, message?: string) => void,
  ) => void
}

/**
 * Describe your component here
 */
export const SubscribeEmail = observer(function SubscribeEmail(props: SubscribeEmailProps) {
  const { style, onSubmit } = props
  const styles = flatten([CONTAINER, style])
  const [error, setError] = useState(null)
  const [validated, setValidated] = useState(InputType.None)
  const [inputValue, setInputValue] = useState("")

  const [isLoading, setIsLoading] = useState(false)

  function validate(value) {
    const isEmail = validator.isEmail(value)
    if (isEmail) {
      return InputType.Email
    }

    if (validator.isMobilePhone(value, ["en-US"])) {
      return InputType.SMS
    }

    return InputType.None
  }

  const handleSubmit = () => {
    setIsLoading(true)
    onSubmit(inputValue, validated, (success, message) => {
      setIsLoading(false)
      if (success) {
      } else {
      }
    })
  }

  const handleInputValueChange = () => {
    const niputType = validate(inputValue)
    setValidated(niputType)
  }

  useEffect(() => {
    handleInputValueChange()
    return () => {
      //
    }
  }, [inputValue])
  console.log("Validead", validated)
  return (
    <View style={styles}>
      <FormControl
        isInvalid={error ? true : false}
        w={
          {
            // base: "75%",
            // sm: "100%",
            // md: "75%",
          }
        }
      >
        <Input
          size="lg"
          style={{ color: "white" }}
          _hover={{ style: { color: "white", backgroundColor: "black" } }}
          onChangeText={setInputValue}
          onSubmitEditing={handleSubmit}
          InputRightElement={
            <Button
              isLoading={isLoading}
              disabled={validated === InputType.None}
              colorScheme={validated !== InputType.None ? "secondary" : "gray"}
              // size="xs"
              rounded="none"
              // w="1/6"
              h="full"
              onPress={handleSubmit}
            >
              {"Subscribe"}
            </Button>
          }
          placeholder="Your Email or mobile #"
        />

        {/* <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          Try different from previous passwords.
        </FormControl.ErrorMessage> */}
      </FormControl>
    </View>
  )
})
