import React, { useState, useRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, Dimensions, Modal } from "react-native"
import { Screen, Menu, Footer } from "../../components"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color } from "../../theme"
import { InputType } from "../../components/subscribe-email/subscribe-email"
import { createOrder } from "../../../src/graphql/mutations"
import { API, graphqlOperation } from "aws-amplify"
import { useToast } from "react-native-toast-notifications"
import { About } from "../../components/about/about"
import { Landing } from "../../components/landing/landing"
import { OrderForm } from "../../components/order-form/order-form"
var validator = require("validator")

const { height } = Dimensions.get("window")

const ROOT: ViewStyle = {
  backgroundColor: color.primary,
  flex: 1,
  alignItems: "center",
  height: "100%",
}
export enum Section {
  Landing,
  About,
  Menu,
}
export const HomeScreen = observer(function HomeScreen() {
  const [subscribed, setSubscribed] = useState(false)

  const [showOrderForm, setShowOrderForm] = useState(false)
  const toast = useToast()

  const SV = useRef(null)
  const navigation = useNavigation()

  function showForm() {
    setShowOrderForm(true)
  }
  const scrollTo = (section: Section) => {
    switch (section) {
      case Section.Landing:
        console.log("landing")

        SV.current.scrollTo({ y: height - 105 })

        break
      case Section.About:
        console.log("about")
        SV.current.scrollTo({ y: height - 105 })

        break
      case Section.Menu:
        console.log("menu")

        SV.current.scrollToEnd()

        break

      default:
        break
    }
  }

  function setFunctionOnParentNavigation() {
    navigation.setOptions({
      showForm,
      scrollTo,
    })
  }

  useEffect(() => {
    setFunctionOnParentNavigation()
  }, [SV])

  const orderPressed = () => {
    setShowOrderForm(true)
  }

  // async function addUser(email, type: InputType) {
  //   try {
  //     var todo = { type: "STANDARD" }

  //     switch (type) {
  //       case InputType.Email:
  //         todo["email"] = email
  //         break
  //       case InputType.SMS:
  //         todo["phone"] = email
  //         break

  //       default:
  //         break
  //     }
  //     return API.graphql(graphqlOperation(createUser, { input: todo }))
  //   } catch (err) {
  //     console.log("error creating todo:", err)
  //     throw err
  //   }
  // }

  async function createOrderFunction(name, sms, orderDetails, strains, window, address) {
    try {
      var params = {
        userID: "walter",
        name,
        window,
        deliveryWindow: window,
        placeID: address.place_id,
        placeDescription: address.description,
        products: strains.map((strain) => {
          switch (strain) {
            case 0:
              return "Double Grape"
            case 1:
              return "Sour Stomper"
            case 2:
              return "Skywalker OG"

            default:
              break
          }
        }),
        status: "ORDERED",
      }
      console.log("params ", params)
      if (validator.isEmail(sms)) {
        params.email = sms
      }

      if (validator.isMobilePhone(sms, ["en-US"])) {
        params.sms = sms
      }
      await API.graphql(graphqlOperation(createOrder, { input: params }))
    } catch (error) {
      console.log("errr", error)
      throw error
    }
  }

  // const submit = async (email, type, callback) => {
  //   try {
  //     const result = await addUser(email, type)

  //     console.log("result", result)
  //     callback(true, "yays")
  //     setSubscribed(true)
  //   } catch (error) {
  //     console.log("eror", error)
  //     toast.show(error.errors[0].message, {
  //       type: "warning",
  //       placement: "bottom",
  //       duration: 2500,
  //       animationType: "slide-in",
  //     })

  //     callback(false, error.message)
  //   }
  // }

  return (
    <Screen scrollRef={SV} style={ROOT} preset="scroll">
      <Landing
        downArrowPressed={() => {
          scrollTo(Section.About)
          // SV.current.scrollTo({ y: height - 105 })
        }}
      />
      <About
        downArrowPressed={() => {
          SV.current.scrollToEnd()
        }}
      />
      <Menu orderPressed={orderPressed} />

      {/* <Text style={SMALLTEXT} text="Coming soon..." preset="bold" /> */}
      {/* <Transitioning.View ref={ref} transition={transition}> */}
      <Footer
        subscribed={subscribed} //submit={submit}
      />
      {/* </Transitioning.View> */}

      <Modal
        presentationStyle="overFullScreen"
        // style={{ justifyContent: "center" }}
        animationType="slide"
        transparent={true}
        visible={showOrderForm}
      >
        <OrderForm
          onSubmit={async (name, sms, orderDetails, strains, window, address) => {
            await createOrderFunction(name, sms, orderDetails, strains, window, address)
          }}
          onCancel={() => {
            setShowOrderForm(false)
          }}
        />
      </Modal>
    </Screen>
  )
})
