import React, { useState } from "react"
import { StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../theme"
import { flatten } from "ramda"
import { Input, FormControl, Button, Select, CloseIcon, Text, Box, Spinner } from "native-base"
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete"
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler"
import { Strains, Success } from ".."
import awsmobile from "../../../src/aws-exports"
const ENDPOINT = awsmobile.aws_cloud_logic_custom[0].endpoint

var validator = require("validator")

const CONTAINER: ViewStyle = {
  // flex: 1,
  padding: spacing[4],
  marginTop: spacing[3],
  paddingTop: spacing[4],
  paddingBottom: spacing[6],
  paddingRight: spacing[0],

  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: color.palette.black,
  alignSelf: "center",
  borderRadius: 9,
  shadowColor: "rgba(52, 52, 52, 0.4)",
  shadowOffset: { width: 2, height: 3 },
  height: "90%",
  width: "80%",
}

const INPUT: StyleProp<TextStyle> = {
  color: "white",
  // backgroundColor: "transparent",
  borderColor: "white",
}

export interface OrderFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  onSubmit: (name, sms, orderDetails, strains, window, address) => void
  onCancel: () => void
}

function verifyContactMethod(contactMethod) {
  if (!contactMethod) {
    return false
  }
  if (validator.isEmail(contactMethod)) {
    return true
  }
  if (validator.isMobilePhone(contactMethod, ["en-US"])) {
    return true
  }
}

enum State {
  Input,
  Loading,
  Success,
  Error,
}
/**
 * Describe your component here
 */
export const OrderForm = observer(function OrderForm(props: OrderFormProps) {
  const { style, onSubmit, onCancel } = props
  const styles = flatten([CONTAINER, style])

  const [name, setName] = useState("")
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [strain, setStrain] = useState(null)
  const [deliveryWindow, setDeliveryWindow] = useState(null)
  const [contactMethod, setContactMethod] = useState(null)
  const [loading, setLoading] = useState(State.Input)

  const enabled = deliveryAddress && deliveryWindow && verifyContactMethod(contactMethod)

  async function submitFunction() {
    if (enabled) {
      setLoading(State.Loading)

      try {
        await onSubmit(name, contactMethod, null, strain, deliveryWindow, deliveryAddress)
        setLoading(State.Success)
      } catch (error) {
        setLoading(State.Error)
      }
    }
  }
  const GOOGLE_PARAMS = {
    useOnPlatform: "web", // or "all"
    url: `${ENDPOINT}/items`, // or any proxy server that hits https://maps.googleapis.com/maps/api
    headers: {
      Authorization: `an auth token`, // if required for your proxy
    },
  }

  function content() {
    switch (loading) {
      case State.Input:
        return (
          <FormControl
            // isInvalid
            w={{
              base: "75%",
              md: "75%",
            }}
          >
            <Box pb="2">
              <Text p={"1.5"} color={"white"} fontSize={["sm", "md", "lg"]}>
                Your Name
              </Text>
              <Input
                background={"transparent"}
                variant="outline"
                color={"white"}
                placeholder="Name"
                value={name}
                onChangeText={setName}
                _hover={null}
              />
            </Box>
            <Box pb="2">
              <Text p={"1.5"} pb={"0"} fontSize={["sm", "md", "lg"]} color={"white"}>
                Delivery Address
              </Text>
              <Text pl={"1.5"} py="0" color={"white"} fontSize={["2xs", "xs"]}>
                Please select an address
              </Text>
              <GooglePlacesAutocomplete
                placeholder="Enter delivery address"
                onPress={(data, details = null) => {
                  // 'details' is provided when fetchDetails = true
                  setDeliveryAddress(data)
                }}
                query={{
                  key: "AIzaSyBneUvR2mhedhQPFCphEbTnhufAfII0b18",
                  language: "en",
                }}
                requestUrl={GOOGLE_PARAMS}
              />
            </Box>
            <Box pb="2">
              <Text p={"1.5"} fontSize={["sm", "md", "lg"]} color={"white"}>
                Strains
              </Text>
              <Strains
                onChange={(selectedItems) => {
                  console.log(selectedItems)
                  setStrain(selectedItems)
                }}
              />
            </Box>
            <Box pb="2">
              <Text p={"1.5"} color={"white"} fontSize={["sm", "md", "lg"]}>
                Delivery Window
              </Text>
              <Select
                // isDisabled={true}
                // _text={{ color: "red.100", textColor: "red.100" }}
                defaultValue="Pick a time.."
                placeholderTextColor={"gray.400"}
                color={"white"}
                placeholder="Delivery Window"
                selectedValue={deliveryWindow}
                width={150}
                onValueChange={(itemValue: string) => {
                  console.log("item value: ", itemValue)
                  setDeliveryWindow(itemValue)
                }}
              >
                <Select.Item color={"blue"} label="Tues 6-9 PM" value="Tues 6-9 PM" />
                <Select.Item color={"blue"} label="Fri 6-10 PM" value="Fri 6-10 PM" />
              </Select>
            </Box>
            <Box pb="2">
              <Text p={"1.5"} color={"white"} fontSize={["sm", "md", "lg"]}>
                Phone #
              </Text>
              <Input
                background={"transparent"}
                _hover={null}
                color="white"
                variant="outline"
                placeholder="Enter phone number"
                value={contactMethod}
                onChangeText={setContactMethod}
                onSubmitEditing={submitFunction}
              />
            </Box>
            {/* <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          Try different from previous passwords.
        </FormControl.ErrorMessage> */}
          </FormControl>
        )

      case State.Loading:
        return <Spinner size={"lg"} />
      case State.Success:
        return <Success prompt="Check your sms for a confirmation message." />

      case State.Error:
        return <Spinner size={"lg"} />
      default:
        return []
    }
  }
  function buttonTitle() {
    console.log("its this ", loading)
    switch (loading) {
      case State.Input:
        return "Submit"

      case State.Loading:
        return "Loading"
      case State.Success:
        return "OK"

      case State.Error:
        return "OK"
      default:
        return []
    }
  }

  return (
    <View style={styles}>
      <View style={{ width: "100%", alignItems: "center" }}>
        <View style={{ width: "100%", alignItems: "flex-end", marginRight: spacing[6] }}>
          <TouchableOpacity onPress={onCancel}>
            <CloseIcon style={{ alignSelf: "flex-end", color: "white", width: 20 }} />
          </TouchableOpacity>
        </View>
        <Text mb="3" color={"white"} fontSize={["2xl", "3xl", "4xl"]}>
          Order your Bud
        </Text>
      </View>
      <ScrollView style={{ width: "90%", flex: 1 }}>
        {content()}
        <Button
          isDisabled={!enabled}
          style={{ marginTop: spacing[4] }}
          onPress={loading === State.Input ? submitFunction : onCancel}
        >
          {buttonTitle()}
        </Button>
      </ScrollView>
    </View>
  )
})
